
import { useSelector, useDispatch } from "react-redux";
import Icon from "@identitybuilding/idb-react-iconlib";
import React from "react";
import "../assets/css/FollowingConflict.css"
import { Button } from "@identitybuilding/idb-react-ui-elements";
import { toggleOpenFollowingConflict } from '../actions/index'
import axios from 'axios'


const FollowingConflict = () => {
    const { translate, lang } = useSelector(state => state.general);
    const { openFollowingConflict } = useSelector(state => state.account);
    const dispatch = useDispatch();


    const SwitchFollowerHandler = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("promotoken")}`,
        };
        axios.post(`https://management.100procentlokaal.be/core/api/catalogus/switch_following_establishment/?lang=${lang}`, {
            headers: headers,
            token: localStorage.getItem("promotoken"),
            data: openFollowingConflict
        }).then(() => {
            window.location.reload()
        })
    }

    return (
        <div className="follwongConflict">
            <div className="container">
                <Icon name="Close" onClick={() => dispatch(toggleOpenFollowingConflict(false))} />
                <h3>{translate('you_cant_follow_2_stores_of_the_same_category')}</h3>
                <span>{translate('you_follow')}: <b>{openFollowingConflict.name}</b></span>
                <figure><img src={openFollowingConflict.main_logo} alt="logo of already following establishment" /></figure>


                {lang === 'nl' ?
                    <span>Wil je van <b>{openFollowingConflict.current_est[`name`] || openFollowingConflict.current_est[`name_${lang}`] || openFollowingConflict.current_est.name_nl || openFollowingConflict.current_est.name_fr || openFollowingConflict.current_est.name_de || openFollowingConflict.current_est.name_en}</b> jouw favoriete winkel maken?</span>
                    :
                    <span>Voulez-vous faire de <b>{openFollowingConflict.current_est[`name`] || openFollowingConflict.current_est[`name_${lang}`] || openFollowingConflict.current_est.name_nl || openFollowingConflict.current_est.name_fr || openFollowingConflict.current_est.name_de || openFollowingConflict.current_est.name_en}</b> votre magasin préféré ?</span>
                }

                <div className="buttons">
                    <Button
                        borderColor='success'
                        text={`${translate('yes_message')}`}
                        txtColor='success'
                        type='sub'
                        size='S'
                        onClick={() => SwitchFollowerHandler()}
                    />
                    <Button
                        borderColor='error'
                        text={`${translate('no_message')}`}
                        txtColor='error'
                        type='sub'
                        size='S'
                        onClick={() => dispatch(toggleOpenFollowingConflict(false))}
                    />
                </div>
            </div>
        </div>
    );
}

export default FollowingConflict;
