
export const toggleLoginScreen = value => ({ 
    type: "TOGGLE_LOGIN_SCREEN", 
    value 
})

export const updateAccountInfo = value => ({ 
    type: "UPDATE_ACCOUNT_INFO", 
    value 
})

export const removeFromSavedCMPs = value => ({ 
    type: "REMOVE_FROM_SAVED_CMPS", 
    value 
})

export const removeFollowingEstablishment = value => ({
    type: "REMOVE_FOLLOWING_ESTABLISHMENT",
    value
})

export const toggleOpenFollowingConflict = value => ({
    type: "TOGGLE_OPEN_FOLLOWING_CONFLICT",
    value
})

export const toFollowEst = value => ({
    type: "TO_FOLLOW_EST",
    value
})